import React from 'react';
import { useQuery } from '@apollo/client';
import { Card, CardContent, CardMedia, Grid, Typography, Chip, CardActions, Box, Switch } from '@mui/material';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import Sub from './prods/sub';
import { makeStyles } from '@mui/styles';
import bean from '../../images/brkoin.png';
import StoreAdd from './adds/storeadd';
import { useState } from 'react';

const useStyles = makeStyles({
    transparentChip: {
        backgroundColor: '#077336',
        border: '1px solid',
        borderColor: '#000',
        color: '#32a852',
    },
});

export const MY_ADDS = gql`
  query MyAdds($isAdd: Boolean!) {
    aiadds(query: { isAdd: $isAdd }) {
      Title
      When
      _id
      description
      price
      primaryImage
      productID
      userID
      isAdd
      imageurl
    }
  }
`;
 
export const GET_IMAGE = gql`
  query GetImage($_id: ObjectId!) {
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
        Nickname
    }
  }
`;

function UserMarket() {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(true);
    const { loading, error, data } = useQuery(MY_ADDS, {
        variables: { isAdd: true },
    });

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (


        <div style={{  display: 'flex', flexDirection: 'column', gap: 10 , padding: 4, marginBottom: 40}}>
            
            <div>
               <Typography variant="body1" sx={{  fontWeight: 900, mb: 1 }}>
               Now accepting Fiat. Seller can choose to accept BEC or Fiat in V2
                </Typography>
                <Typography variant="body1" sx={{  fontWeight: 700, mb: 2 }}>
                    Build the BEC economy by using BEC to buy and sell
                </Typography>
                        <Link to="/logic" style={{ textDecoration: 'none' }}>
                            <Chip
                                label="onboard"
                                sx={{
                                    backgroundColor: '#077336',
                                    color: 'white',
                                    border: '1px solid grey',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                }}
                            />
                        </Link>
                <Grid container spacing={2}>
                    {data.aiadds.map((add) => (
                        <Grid item key={add._id} xs={6} sm={6} md={4}>
                            <Card sx={{ boxShadow: 0, borderRadius: 0, paddingBottom: 0 }}>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={add.primaryImage}
                                    alt={add.Title}
                                    sx={{ objectFit: 'cover',  borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                />
                                <CardContent sx={{  padding: 1, paddingBottom: 0 }}>
                                
                                <Link to={`/fromadds/${add._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                              
  <Typography gutterBottom variant="body2" fontWeight={500}
  sx={{ overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',}}
  >
    {add.Title}
   
  </Typography>
</Link>                        
<UserImage userID={add.userID} />
<CardActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                {!isActive ? (
                    <Link to={`/frombeans/${add._id}`} style={{ textDecoration: 'none' }}>
                        <Chip
                            label='2k'
                            icon={<img alt="Kup" src={bean} width={15} />}
                            sx={{
                               
                                color: '#32a852',
                                border: '1px solid #32a852',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                    </Link>
                ) : (
                    <Chip
                        label='2k'
                        icon={<img alt="Kup" src={bean} width={15} />}
                        sx={{
                            backgroundColor: '#e0e0e0',
                            color: 'grey',
                            border: 'none',
                            borderRadius: 5,
                            cursor: 'default',
                        }}
                    />
                )}

                {isActive ? (
                    <Link to={`/fromadds/${add._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Chip
                            label={`$${add.price}`}
                            sx={{
                                backgroundColor: '#077336',
                                color: 'white',
                                border: '1px solid grey',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                        {add.ProductID}
                    </Link>
                ) : (
                    <Chip
                        label={`$${add.price}`}
                        sx={{
                            backgroundColor: '#e0e0e0',
                            color: 'white',
                            border: 'none',
                            borderRadius: 5,
                            cursor: 'default',
                        }}
                    />
                )}
            </CardActions>
    
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                <Switch
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    sx={{
                        backgroundColor: 'transparent',
                        '& .MuiSwitch-thumb': { backgroundColor: '#32a852' },
                        '& .MuiSwitch-track': { backgroundColor: '#392c12' },
                        '& .MuiSwitch-root': { width: 100, height: 40, backgroundColor: 'transparent' },
                        '& .MuiSwitch-switchBase': { padding: 1, backgroundColor: 'transparent' },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#32a852' }
                    }}
                />
                <Typography variant="caption" sx={{ color: 'black', fontWeight: 700 }}>
                    {isActive ? 'Fiat' : 'BEC'}
                </Typography>
            </Box>
                                </CardContent>
                               
    
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
            
            
           
        </div>
    );
}

// Component to fetch and display user image
function UserImage({ userID }) {
    const { loading, error, data } = useQuery(GET_IMAGE, {
        variables: { _id: userID },
    });

    if (loading) return <img src="https://via.placeholder.com/30" alt="Loading user" style={{ width: 30, height: 30, borderRadius: 50 }} />;
    if (error) return <img src="https://via.placeholder.com/30" alt="Error loading user" style={{ width: 30, height: 30, borderRadius: 50 }} />;

    const userImage = data.userprofs[0]?.imageurl || 'https://via.placeholder.com/30';
    const name = data.userprofs[0]?.Nickname || 'User';

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <img src={userImage} alt="user" style={{ width: 20, height: 20, borderRadius: '50%' }} />
            <Typography variant="caption" sx={{ mb: 0 }}>
                {name}
            </Typography>
        </Box>
    );
}

export default UserMarket;
