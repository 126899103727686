import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Typography } from '@mui/material';

const images = [
    'https://storage.googleapis.com/app_darkendimg/lilbeanie.png',  // Example image URLs
    'https://storage.googleapis.com/app_darkendimg/assets/purp.png',
    'https://storage.googleapis.com/app_darkendimg/youngbean.png',
    'https://storage.googleapis.com/app_darkendimg/assets/madbean.png',
    'https://storage.googleapis.com/app_darkendimg/assets/royal.png',
    'https://storage.googleapis.com/app_darkendimg/newton.png',
  ];

const burstVariants = {
  initial: { opacity: 0, scale: 0 },
  animate: (index) => ({
    opacity: 1,
    scale: [1, 1.2, 1],
    x: Math.cos((index * Math.PI) / 3) * 150,
    y: Math.sin((index * Math.PI) / 3) * 150,
    transition: {
      delay: index * 0.1,
      duration: 0.1,
      ease: 'easeOut',
    },
  }),
  exit: { opacity: 0, scale: 0 },
};

const BurstEffect = () => {
  const [burst, setBurst] = useState(false);

  const handleClick = () => {
    setBurst((prev) => !prev);
    console.log("Burst Triggered:", !burst);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
       
      }}
    >
      {/* Replace the motion div with a clickable button */}
      <motion.div
        style={{
          position: 'relative',
          width: '80px',
          height: '80px',
          borderRadius: '50%',
          backgroundColor: '#007336',
          cursor: 'pointer',
        }}
        onClick={handleClick}  // This triggers the burst
      >
        <Typography sx={{ textAlign: 'center', color: '#fff' }}>Click Me</Typography>

        {burst &&
          images.map((src, index) => (
            <motion.img
              key={index}
              src={src}
              alt={`burst-${index}`}
              initial="initial"
              animate="animate"
              exit="exit"
              custom={index}
              variants={burstVariants}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50px',
                height: '50px',
              }}
            />
          ))}
      </motion.div>
    </Box>
  );
};

export default BurstEffect;
