import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { gql, useQuery } from '@apollo/client';
import Progress from '../components/mods/progress';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


const GET_FAQ = gql`
  query GetFAQ {
    faqs {
      _id
      question
      answer
    }
  }
`;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Faq() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { loading, error, data } = useQuery(GET_FAQ);
  if (loading) return <p><Progress /></p>;
  if (error) return <p>Error loading FAQs</p>;

  return (
    <div style={{ height: '100vh' }}>
     <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', mt: 1 }}>
  <IconButton
    sx={{
      position: 'absolute',
      left: 0,
      color: 'text.secondary',
      fontSize: '1rem',
    }}
    onClick={() => window.history.back()}
  >
    <Close />
  </IconButton>
  <Typography
    variant="h6"
    sx={{
      fontWeight: 700,
      textAlign: 'center',
      color: 'text.secondary',
      width: '100%', // Ensure the text takes the full width
    }}
  >
    Frequently Asked Questions
  </Typography>
</Box>

<Box sx={{ backgroundImage: 'url(https://storage.googleapis.com/app_darkendimg/lovekoffie.webp)',

  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  objectFit: 'contain',
  width: '100%',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',}}>

        </Box>
      {data.faqs.map((faq) => (
        <Accordion
          key={faq._id}
          expanded={expanded === faq._id}
          onChange={handleChange(faq._id)}
        >
          <AccordionSummary aria-controls={`${faq._id}-content`} id={`${faq._id}-header`}>
            <Typography fontWeight={600} color='text.secondary'>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}

    </div>
  );
}
