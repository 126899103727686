import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, CardMedia, Box, Icon, Drawer, Chip, CardActions } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import showme from "../../images/rita.jpg";
import { ArrowForward, ArrowRight, OutboundOutlined } from "@mui/icons-material";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import {Link} from 'react-router-dom';
import Dhome from "../../pages/homedesk";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import beanify  from '../../images/beaniefy.png';
import { useSpring, animated } from 'react-spring';

const GET_STORIES = gql`
  query {
    darkroads(limit: 100) {
      ImageUrl
      When
      How
      What
      Title
      _id
    }
  }
`;

const FactGen = () => {
  const [fact, setFact] = useState("");
  const { loading, error, data } = useQuery(GET_STORIES);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleBounce = () => {
    set({ transform: 'scale(1.2)' });
    setTimeout(() => {
      set({ transform: 'scale(1)' });
    }, 300); // Reset to original scale after 2 seconds
  };

  useEffect(() => {
    if (data && data.darkroads.length > 0) {
      generateFact();  // Generate an initial fact
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      generateFact();
    }, 7000); // 7 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [data]); // Make sure to include data in the dependency array
 
  const generateFact = () => {
    if (data && data.darkroads.length > 0) {
      const randomIndex = Math.floor(Math.random() * data.darkroads.length);
      setFact(data.darkroads[randomIndex]); // Assuming the story is in the 'What' field
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
   <Card sx={{ padding: "0rem", border: 'none' ,  }}>
  <CardContent sx={{ padding: '2px', }}>
    

  {fact && (
  <>
    <Box 
      position="relative" 
      sx={{
        
        borderRadius: 2,
        overflow: 'hidden', // Ensures the child components adhere to the border radius
      }}
    >
      <CardMedia
        component="img"
        width={200}
        image={fact.ImageUrl}
        alt="Story image"
        sx={{ 
          width: '100%', // Ensures the image covers the card width
          
          objectFit: 'cover', // Ensures the image covers the area without distortion
        }}
      />
      <Box 
        position="absolute" 
        top={2} 
        left={0} 
        right={0} // Added to ensure the box takes the full width
        color="white" 
        p={1}
        sx={{
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
          justifyContent: 'space-between', // Space between top and bottom elements
          height: '100%', // Full height of the parent
        }}
      >
        <Typography 
          variant="h4" 
          component="div" 
          sx={{
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', 
            fontWeight: 600,
            mb: 'auto', // Pushes the rest of the content to the bottom
          }}
        >
          {fact.Title}
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            mt: 2, 
            fontWeight: 400, 
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Shadow for bottom text
            alignSelf: 'flex-end', // Aligns the text to the bottom
          }}
        >
          
        </Typography>
      </Box>
    </Box>
  </>
)}

  </CardContent>
  <CardActions disableSpacing sx={{ justifyContent: 'space-between', }}>
    <div>
   <Typography variant="body2" >
    
   <Link to={`/darkroad/${fact._id}`} style={{ textDecoration: 'none', color: '#077336', fontWeight: 700 }}>
    More...
    </Link>
   </Typography>
       

   
    </div>
    <animated.div style={props} 
      
    
        
      >
    <IconButton 
  aria-label="next-bite" 
  onClick={generateFact} 
  sx={{  }}
>
  <img 
    onClick={handleBounce} 
    src={beanify} 
    alt="Rita" 
    width="60" 
    height="60" 
  />
</IconButton>

    </animated.div>
    <IconButton aria-label="next-bite" onClick={generateFact}>
      <ArrowForward/>
    </IconButton>
  </CardActions>
</Card>

</>
  );
};

export default FactGen;
