import React, { useState, useCallback, useRef, useMemo,  useEffect} from 'react';
import { GoogleMap, Marker, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { Typography, Chip, Collapse, Box , Card, CardContent, CardActions, Avatar, Grid, Drawer, TextField, ListItemText, CardMedia,
Dialog,DialogActions,DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material';
import { useQuery, gql , useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import AiGen from '../adds/aifeeds';
import AiGen2 from '../adds/aifeed2';
import { Link } from 'react-router-dom';
import { Redeem } from '@mui/icons-material';

import { UserContext } from '../../../context/usercontext';
import { useContext } from 'react';
import { UPDATE_USER_PROF, GET_USER, REDEEM_BEANS, GET_REDEEMED_BY_SHOP, GET_REDEEMED_BY_USER } from '../../../context/gqllogics';
import { get } from 'lodash';
import kcoin from '../../../images/kcoin.png';
import { render } from '@testing-library/react';
import RewardsBanner from '../rewards';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { to } from 'react-spring';
import StoreAdd from '../adds/storeadd';
import { TransitionProps } from '@mui/material/transitions';


const coin = 'https://storage.googleapis.com/app_darkendimg/assets/brkoin.png';

const apiKey = 'AIzaSyAGEvhVRFmxBBiSsZ8e7LI11slf89LHfMI'; // Replace with your actual API key

const useStyles = makeStyles(({  theme }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: 'center',
    transition: '0.3s',
  },
  avatar: {
    width: 50,
    height: 50,
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
  },
  media: {
    minHeight: 250,
    width: 345,
    borderRadius: 10,
  },
  search: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    borderRadius: '5px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipses',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '80%',
    height: '40px',
    borderRadius: 20,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
  },
}));

const REWARDS = gql`
    query Rewards {
        rewards {
            _id
            Title
            description
            beans
            imageurl
            available
        }
    }
`;

const GET_COFFEE_SHOPS = gql`
  query userprofs ($usertype: String!) {
    userprofs (query: {usertype: $usertype}) {
      _id
      Nickname  
      Title
      Koffie_story
      usertype
      imageurl
    }
  }
`;
const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
      likecount
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



function CoffeeShopSearch({ shopId }) {
  const { user } = useContext(UserContext);
  const { Nickname, beanCount, imageurl } = user.customData
  const libraries = useMemo(() => ["places"], []);
  const [coffeeShops, setCoffeeShops] = useState([]);
  const searchBoxRef = useRef(null);
  const [openSummary, setOpenSummary] = useState(false);
  const classes = useStyles();
  const [selectedShop, setSelectedShop] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [beansNeeded, setBeansNeeded] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [totalLikeCount, setTotalLikeCount] = useState(0);
  const navigate = useNavigate();

  
  const handleStepChange = (index) => {
    // You can handle any necessary actions when the slide changes
    console.log("Changed to slide", index);
}; 
const [index, setIndex] = useState(0);

    const handleNext = () => {
        setIndex(prev => (prev + 1) % rewardsData.rewards.length);
    };

    const handleBack = () => {
        setIndex(prev => (prev - 1 + rewardsData.rewards.length) % rewardsData.rewards.length);
    };
const { loading, error, data } = useQuery(GET_COFFEE_SHOPS, {
  variables: { usertype: "coffeeShop" },
});


 // Query for roasters
 const {
  loading: loadingRoasters,
  error: errorRoasters,
  data: dataRoasters
} = useQuery(GET_COFFEE_SHOPS, {
  variables: { usertype: "roaster" },
});

const { data: dataUser, loading: loadingUser, error: errorUser, refetch } = useQuery(GET_USER, {
  variables: {  _id: user.id},
});
console.log("UserID for query:", user.id);  // Make sure user.id is not undefined or incorrect
const userprof = dataUser?.userprof;


const [updateUserprof] = useMutation(UPDATE_USER_PROF);
const contentCreatorId = typeof user?.id === 'object' ? user.id.id : user.id;
const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
  variables: { contentCreator: contentCreatorId }, // Assuming user.id is a string
});
// likes count

// User Redeemed Count



// Calculate the total rewards
const { loading: loadingUserprof, error: errorUserprof, data: dataUserprof } = useQuery(GET_REDEEMED_BY_USER, {
  variables: { userId: user.id },
});

// Calculate the total rewards, ensuring each reward is treated as a number
const rewardsCount = dataUserprof?.redeems.reduce((acc, redeem) => acc + Number(redeem.reward), 0) || 0;
console.log(`Total Rewards Count: ${rewardsCount}`);




// comments count
const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
  variables: { contentCreator: user?.id }, // Assuming user.id is a string
});
const [rewards, setRewards] = useState(0);



useEffect(() => {
  if (!loadingLikes && !errorLikes && likesData) {
    // Sum up all likeCount values
    const sumOfLikeCounts = likesData.likes.reduce((total, like) => total + (like.likecount || 0), 0);
    setTotalLikeCount(sumOfLikeCounts);

    console.log(`Total Like Count: ${sumOfLikeCounts}`);
  }
}, [loadingLikes, errorLikes, likesData, setTotalLikeCount]);

// Query for rewards
const { data: rewardsData, loading: rewardsLoading, error: rewardsError } = useQuery(REWARDS);

const [redeemBeans] = useMutation(REDEEM_BEANS, {
  onCompleted: (data) => {
    console.log("Beans redeemed successfully", data);
    updateUserprof({
      variables: {
        query: { _id: userprof._id },
        set: { redeemed: userprof.redeemed - rewards }
      }
    });
  },

  onError: (error) => {
    console.error("Error redeeming beans", error);
  }
});

// retrieve selected shop redeem data
const { loading: redeemLoading, error: redeemError, data: redeemData } = useQuery(GET_REDEEMED_BY_SHOP, {
  variables: { selectedShop: selectedShop?._id }
});


const handleSubmit = (reward) => {
  if (!selectedShop) {
    alert('No shop selected. Please select a shop before redeeming rewards.');
    return;
  }

  const currentRewardsCount = Number(totalLikeCount) - Number(rewardsCount);
  console.log(`Current Rewards Count: ${currentRewardsCount}`);

  if (reward.beans > currentRewardsCount) {
    const neededBeans = reward.beans - currentRewardsCount;
    setBeansNeeded(neededBeans); // Update the state with the required beans
    setShowFailureModal(true); // Show the failure modal with the updated message
    return;
  }

  const redeemData = {
    Title: reward.Title,
    reward: reward.beans,
    userID: user.id,
    imageurl: reward.imageurl,
    redeemedAt: new Date().toISOString(),
    shop: selectedShop._id
  };

  redeemBeans({
    variables: { data: redeemData },
    onCompleted: () => {
      setShowSuccessModal(true);
      console.log("Reward redeemed successfully!");

      // Refetch the user profile to get the updated rewards count
      refetch()
        .then(() => console.log("User profile data refetched successfully!"))
        .catch(error => console.error("Error refetching user profile data", error));
    },
    onError: (error) => {
      console.error("Error redeeming beans", error);
      alert("Failed to redeem reward. Please try again.");
    }
  });
};


  // SetDialogOpen


const handleOpenDialog = (shop) => {
  setSelectedShop(shop);
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  setSelectedShop(null); // Clear the selected shop
};
// SetDialogOpen

  const handleSearch = useCallback(() => {
    if (!searchBoxRef.current || !searchBoxRef.current.getPlaces()) {
      console.error("No places found");
      return;
    }
  
    const places = searchBoxRef.current.getPlaces();
console.log("Places:", places);
    if (!Array.isArray(places) || places.length === 0) {
      console.error("No places found");
      return;
    }
  
    const shops = places.map(place => {
      if (!place.geometry || !place.geometry.location) {
        console.error("Place has no location data");
        return null;
      }
  
      // Fetch additional details like place URL and reviews using place.place_id
      // This may require a separate API call to Google Places API
     
      
      return {
        name: place.name,
        address: place.formatted_address,
        location: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
       placeId: place.place_id,
        url: place.url,
        openingHours: place.opening_hours,
        rating: place.rating,
        reviews: place.reviews,
        icon: place.icon,
        // Additional fields like placeUrl, reviewStars, etc.
      };
    }).filter(shop => shop !== null);
  
    setCoffeeShops(shops);
  }, []);
  
  
  const renderShopDetails = () => {
   
  
    const handleSummaryClick = () => {
      // Function to trigger AI summary - Replace with your AI summary logic
      setOpenSummary(!openSummary);
    };

      loading && <p>Loading...</p>;
      error && <p>Error: {error.message}</p>;

      
  
    return 
coffeeShops.map((shop, index) => (
      <div key={index} style={{ margin: '2px', padding: '10px', boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)', }}>
        
        <Typography variant="h6">{shop.name}</Typography>
        
        <img src={shop.icon} alt={shop.name} style={{ width: '50px', height: '50px' }} />
        <Typography variant="body2">{shop.address}</Typography>
        <Typography variant="h6">Rating: {shop.rating}</Typography>
  
        {shop.reviews && shop.reviews.length > 0 ? (
          <div>
            <Typography variant="subtitle2" gutterBottom>Reviews:</Typography>
            {shop.reviews.map((review, reviewIndex) => (
              <Collapse in={openSummary} collapsedSize={60} key={reviewIndex}>
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  <strong>{review.author_name}</strong>: {review.rating} stars
                  <br />
                  <Typography variant="heading" style={{ color: '#fff', fontWeight: 500 }}>
                  {review.text}
                  </Typography>
                </Typography>
              </Collapse>
            ))}
            <Chip
              label={openSummary ? "Hide Summary" : "Summarize"}
              onClick={handleSummaryClick}
              color="primary"
              variant="outlined"
              style={{ marginTop: '10px' }}
            />
          </div>
        ) : <Typography variant="body2">No reviews available.</Typography>}
      </div>
    ));
  };

  const currentRewardsCount = totalLikeCount - rewardsCount;
  const renderCard = (userProfile, ) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
      <Card
sx={{ display: 'flex', flexDirection: 'column', padding: 0, width: '100%', height: '100%', borderRadius: 5, }}
>
      <CardContent
       sx={{ display: 'flex', flexDirection: 'column',  padding: 2, width: '100%', height: '100%', borderRadius: 5, backgroundColor: '#c0c0c0'}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
         <CardMedia
            component="img"
            image={userProfile?.imageurl || 'https://storage.googleapis.com/app_darkendimg/assets/airport.webp'}
            alt="Profile Image"
            sx={{ width: 50, height: 50, borderRadius: '50%', marginRight: 2 }}
          />
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, marginRight: '8px' }}
          >
            {userProfile?.Nickname} Rewards Card
          </Typography>
          
         
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Typography
            variant="caption"
            sx={{ fontWeight: 800, marginRight: '8px' }}
          >
            ID# {userProfile?._id.substring(0, 11)}
          </Typography>
          <Typography variant="h6" sx={{  }}>
         {currentRewardsCount} Beans
          </Typography>
          <Typography variant="caption" sx={{ fontWeight: 400, marginRight: '8px' }}>
            DigiBeans
          </Typography>
          <img src={kcoin} alt="Bean" width={50} />
        </Box>
      </CardContent>
    </Card>
    </Box>
        
    );
}

const renderEspresso = () => {
  return (
      <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
        
    <div className="container">
      <div className="coffee-header">
        <div className="coffee-header__buttons coffee-header__button-one"></div>
        <div className="coffee-header__buttons coffee-header__button-two"></div>
        <div className="coffee-header__display"></div>
        <div className="coffee-header__details"></div>
      </div>
      <div className="coffee-medium">
        <div className="coffee-medium__exit"></div>
        <div className="coffee-medium__arm"></div>
        <div className="coffee-medium__liquid"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-one"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-two"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-three"></div>
        <div className="coffee-medium__smoke coffee-medium__smoke-for"></div>
        <div className="coffee-medium__cup"></div>
      </div>
      <div className="coffee-footer"></div>
    </div>
   
    </Box>
  );
}
  
  
  const renderCoffeeShops = () => {
    return coffeeShops.map((shop, index) => (
      <Marker key={index} position={shop.location} title={shop.name} />
    ));
  };
  const BeanIcon = () => {
    return (
      
      <img src={coin} alt="koffie" width={20} />
    
    );
  };
  const filteredShops = data?.userprofs?.filter(shop =>
    shop.Nickname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <Box sx={{ flexGrow: 1, mx: 'auto', my: 0, paddingBottom: 5, padding: 2 }}>
     
        <RewardsBanner />
        <TextField
  label="Search Participating Coffee Shops"
  variant="outlined"
  fullWidth
  value={searchTerm}
  onChange={e => setSearchTerm(e.target.value)}
  sx={{
    marginBottom: 2,
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "small",
    },
  }}
/>

     <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', gap: 3   }}>
     <Typography variant="body2" gutterBottom sx={{ fontWeight: 400, margin: 1,  }}>
  Rewards <b style={{ color: 'green' }}>{currentRewardsCount}</b>
</Typography>

    
    
    </Box>
    
    <Card sx={{ borderRadius: 2, boxShadow: 0,   }}>
      
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5' , padding: 2, borderRadius: 5 }}>
    <Grid container spacing={2}>
        {filteredShops?.map((shop, index) => (
          <Grid item xs={4} sm={6} md={3} key={index} sx={{ textAlign: 'center' }}>
            <CardContent style={{ textAlign: 'center' }}>
              <Box to="#" onClick={() => handleOpenDialog(shop)} style={{ textDecoration: 'none' }}>
                <Avatar
                  className={classes.avatar}
                  src={shop.imageurl}
                  alt={shop.Title}
                  sx={{ width: 100, height: 100, margin: '0 auto' }}
                />
                <Typography variant="caption" component="div" sx={{ fontWeight: 'bold', color: 'text.secondary', marginTop: 2 }}>
                  {shop.Nickname}
                </Typography>
              </Box>
            </CardContent>
          </Grid>
        ))}
      </Grid>
      </Box>
  
</Card>
<Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
       
        <DialogContent>
      
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 2,  borderRadius: 5, marginTop: 4 }}>
   <Avatar src={selectedShop?.imageurl} alt={selectedShop?.Nickname} sx={{ width: 100, height: 100, border: 'solid 5px #077336' }} />
   <Typography variant='h6'>{selectedShop?.Nickname || 'Coffee Shop Details'}</Typography>
         
          
            </Box>
      
          {renderCard(dataUser?.userprof)}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 0,  borderRadius: 5, padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mt: 0,  borderRadius: 5, padding: 2 }}>
            <Typography variant="h6" sx={{mb: 5, fontWeight: 700, margin: 1,  color: '#077336' }}>
              Redeem Rewards
            </Typography>
            </Box>

<Box sx={{  }}>
            <IconButton onClick={handleBack} sx={{ position: 'absolute', left: 8, zIndex: 1, marginTop:5 }}>
                <ArrowBackIosIcon fontSize='large' />
            </IconButton>
            <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 8, zIndex: 1, marginTop:5 }}>
                <ArrowForwardIosIcon fontSize='large' />
            </IconButton>
            <AutoPlaySwipeableViews
                index={index}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={4000}
            >
                {rewardsData && rewardsData.rewards.map((reward, index) => (
                    <Box key={index} sx={{ padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar src={reward.imageurl} alt={reward.Title} sx={{ width: 80, height: 80 }} />
                        <Typography variant="h6">{reward.Title}</Typography>
                        <Typography variant="body2">{reward.description}</Typography>
                        <Chip
                            icon={<BeanIcon />}
                            label={reward.beans}
                            
                            sx={{ marginTop: 4 }}
                        />
                        <button className={classes.buttonContainer} onClick={() => handleSubmit(reward)}>Redeem</button>
                    </Box>
                ))}
            </AutoPlaySwipeableViews>
        </Box>
        </Box>
        </DialogContent>
        <DialogActions>
          <Typography sx={{color: 'green'}} onClick={handleCloseDialog}>Close</Typography>
        </DialogActions>
        <Dialog open={showSuccessModal} onClose={() => setShowSuccessModal(false)}>
  <DialogTitle>Success!</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Your reward has been redeemed successfully.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Typography sx={{color: 'green'}} onClick={( ) => navigate('/post')}>Post Drink</Typography>
  </DialogActions>
</Dialog>
<Dialog open={showFailureModal} onClose={() => setShowFailureModal(false)}>
  <DialogTitle>Insufficient Beans</DialogTitle>
  <DialogContent>
    <DialogContentText>
      You need <b style={{color:'green'}} >{beansNeeded}</b> more DigiBeans to redeem this reward.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Typography sx={{color: 'green'}} onClick={() => setShowFailureModal(false)}>Close</Typography>
  </DialogActions>
  </Dialog>
</Dialog>



 <StoreAdd />

    <AiGen2/>
    
   </Box>

  );
  
}

export default CoffeeShopSearch;
