import React from 'react'
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom'
import { Card, Paper , Box, Divider, Typography, IconButton} from '@mui/material';


import Darkswipe from '../components/mods/darkswipe';

import Koffieticket from '../components/finance/prods/coffee';
import { ArrowBackIos } from '@mui/icons-material';


const GET_DARK = gql`
  query Dark ($_id: ObjectId!)  {
  darkroad (query: {_id: $_id}) {
   _id
    Title
    When
    Who
    What
    Why
    How
    Country
    ImageUrl
  }
}
`; 

function Dark() {
    const { _id } = useParams();
    const { loading, data, error } = useQuery(GET_DARK, {
    variables: { _id },
    skip: !_id // Skip the query if _id is not available
    });

    if (!_id) {
        return <div>Error: ID not found in URL</div>;
      }
    
      if (loading) return <div>Loading...</div>;
      if (error) return <div>Unexpected Error: {error.message}</div>;
    
  return (
    <div  key={data.darkroad._id} style={{ padding: 10,spacing: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
    <IconButton aria-label="go back" sx={{ color: 'green' }} onClick={() => window.history.back()}>
        <ArrowBackIos />
    </IconButton>
</Box>

        <Card
  elevation={24}
  sx={{ 
    borderRadius: 0, 
    backgroundImage: `url(${data.darkroad.ImageUrl})`,
    backgroundSize: 'cover',  // Set the dimensions of the background image
    backgroundRepeat: 'no-repeat',  // Do not repeat the background image
    backgroundPosition: 'center',  // Center the background image
    width: '100%',  // Optional: Set the width of the card itself
    height: 300,  // Optional: Set the height of the card itself
    marginBottom: 2,
  }}
>
      
        </Card>
      
    <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2, fontWeight: 'bold', fontSize: "2.0rem"   }}>
            {data.darkroad.Title}
        </Typography>
        <Typography variant="h5" color="textSecondary">
            {data.darkroad.Country}, {data.darkroad.When}
        </Typography>
    </Box>

    <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">{data.darkroad.What}</Typography>
        <Typography></Typography>
    </Box>

    <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" color="textSecondary">{data.darkroad.Who}</Typography>
        <Typography></Typography>
    </Box>

    <Divider sx={{ marginBottom: 3 }} />

    <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6"></Typography>
        <Typography>{data.darkroad.Why}</Typography>
    </Box>

    <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">How:</Typography>
        <Typography>{data.darkroad.How}</Typography>
    </Box>

    <Divider sx={{ marginBottom: 3 }} />

  


<>
<Typography variant="h4" color="textSecondary">Related Stories</Typography>
        <Darkswipe/>

        </>
        <Typography variant="h4" color="textSecondary">Koffie</Typography>
        <Koffieticket/>
                   
    </div>
  )
}

export default Dark