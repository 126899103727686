import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Chip,
  Icon,
  IconButton,
} from '@mui/material';
import useKupToken from '../../blockchain/useKupToken';
import { Web3ModalContext } from '../../constants/Web3ModalProvider';
import { useContext } from 'react';
import eth from '../../images/ethstar.svg';
import Koffieticket from './prods/coffee';
import CountryTicket from './prods/countryfeed';
import PriceTicket from './prods/pricefeed';
import { Close, Construction, ConstructionOutlined } from '@mui/icons-material';
import Wallet from '../../constants/wallet';


const Marketplace = () => {
  // Mock data for NFT items

  const renderMedia = (url, title, backgroundColor) => {
    const isVideo = url.match(/\.(mp4)$/i);
    return isVideo ? (
      <video controls width="250" style={{  padding: 0 }}>
        <source src={url} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    ) : (
        <CardMedia
        component="img"
        image={url}
        alt={title}
        width="100%"
        sx={{ borderRadius: '10px 10px 0px 0px', backgroundColor: backgroundColor }}
        />
        );
     
  };

    const { account, connect } = useContext(Web3ModalContext);
  const { totalCherries } = useKupToken(account);
  const [nftItems, setNftItems] = useState([
    {
      id: 1,
      title: 'The Tree of Life',
      description: 'Grow a tree and keep koffie alive',
      image: 'https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png',
      price: .9,
      backgroundColor: '#3cb371',
    },
    {
      id: 2,
      title: 'The Why',
      description: 'Description for NFT Item 2',
      image: 'https://storage.googleapis.com/app_darkendimg/lilbean.png',
        price: 8,
        backgroundColor: '#9370db',
    },
    {
        id: 1,
        title: 'Take Koffie',
        description: 'Fight the good fight angains Big Koffie',
        image: 'https://storage.googleapis.com/app_darkendimg/lilbeanie.png',
        price: 2.5,
        backgroundColor: '#ba55d3',
      },
      {
        id: 1,
        title: 'Turn of the Century',
        description: 'The World as it was 2003',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/purp.png',
        price: 1,
        backgroundColor: '#dda0dd',
      },
      {
        id: 1,
        title: 'GK Morning Show  Playground Pilot ',
        description: 'Behind the scen of the GK Morning Show 2014',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/tinbean.png',
        price: 1.7,
        backgroundColor: '#e9d66b',
      },
      {
        id: 1,
        title: 'GK Morning Show Episode 1',
        description: 'Koffie Planting the Seed of the Future 2011',
        image: 'https://storage.googleapis.com/app_darkendimg/youngbean.png',
        price: 2,
        backgroundColor: '#431c53',
      },
      {
        id: 1,
        title: 'NFT Video',
        description: 'Description for NFT Item 1',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/trooper.png',
        price: 3 ,
        backgroundColor: '#2d8c39',
      },
      {
        id: 1,
        title: 'Turn of the Century',
        description: 'The World as it was 2003',
        image: 'https://storage.googleapis.com/app_darkendimg/iceicebeaine.png',
        price: 1,
        backgroundColor: '#f28e1c',
      },
      {
        id: 1,
        title: 'GK Morning Show  Playground Pilot ',
        description: 'Behind the scen of the GK Morning Show 2014',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/beezy.png',
        price: 1.7,
        backgroundColor: '#54626f',
      },
      {
        id: 1,
        title: 'GK Morning Show Episode 1',
        description: 'Koffie Planting the Seed of the Future 2011',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/bikerbean.png',
        price: 2,
        backgroundColor: '#7c0802',
      },
      {
        id: 1,
        title: 'Jetset Joe',
        description: 'Description for NFT Item 1',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/jetset.png',
        price: 3 ,
        backgroundColor: '#4997d0',
      },
      {
        id: 1,
        title: 'Turn of the Century',
        description: 'The World as it was 2003',
        image: 'https://storage.googleapis.com/app_darkendimg/newton.png',
        price: 1,
        backgroundColor: '#6f4e37',
      },
      {
        id: 1,
        title: 'GK Morning Show  Playground Pilot ',
        description: 'Behind the scen of the GK Morning Show 2014',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/royal.png',
        price: 1.7,
        backgroundColor: '#e7feff',
      },
      {
        id: 1,
        title: 'GK Morning Show Episode 1',
        description: 'Koffie Planting the Seed of the Future 2011',
        image: 'https://storage.googleapis.com/app_darkendimg/assets/madbean.png',
        price: 2,
        backgroundColor: '#4f86f7',
      },
      {
        id: 1,
        title: 'NFT Video',
        description: 'Description for NFT Item 1',
        image: 'https://storage.googleapis.com/app_darkendimg/bennyborer.png',
        price: 3 ,
        backgroundColor: '#d6d6d6',
      },
      
    // Add more NFT items here
  ]);

  // State for dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Function to handle click on NFT item
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  // Function to handle dialog close
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItem(null);
  };

  return (
    <Container sx={{ mb: 10, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
       <IconButton
    sx={{
      position: 'absolute',
      left: 0,
      
      fontSize: '1rem',
    }}
    onClick={() => window.history.back()}
  >
    <Close />
  </IconButton>
  </Box>
        <Box sx={{ width: '100%', paddingTop: 2 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '.5rem', paddingTop: '.5rem', marginLeft: '-10px', marginRight: '-10px'  }}>
            <Card sx={{
        display: 'flex',
        justifyContent: 'center',
        

        }}>
       
           <CardContent>
         
                       <Typography variant="h6"  align="center" sx={{fontWeight: 600}}>
            Bean Heads NFT Market
            </Typography>
            <Typography variant="body1" align="center">
                Stimulate the Koffieland Economy when you collect Bean Heads
            </Typography>
             <Typography variant="caption"  align="center">
              In Development <ConstructionOutlined fontSize='small'/>
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '.5rem', paddingTop: '.5rem', marginLeft: '-10px', marginRight: '-10px'  }}>
              <Wallet/>
              </div>
            </CardContent>
            
            </Card>
           
        </div>
        </Box>
      <Grid container spacing={2}>
        {renderMedia && nftItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card sx={{ borderRadius: '10px 10px 4px 4px', boxShadow: 8,  }}>
              <CardActionArea onClick={() => handleItemClick(item)} sx={{   padding: 0,  }}>
              {renderMedia(item.image, item.title, item.backgroundColor)}
                <CardContent sx={{borderRadius: '0px 0px 10px 10px',  }}>                    
                    <Typography variant="body2"  >{item.title}</Typography>
                    <Typography variant="body2" fontWeight={500} >
                        {item.number}
                    </Typography>
                    <Typography variant="h6" >
                        {totalCherries}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="body1" fontWeight={400}>
                        {item.price} ETH <img src={eth} alt="ETH" style={{ width: 20, height: 20 }} />
                    </Typography>
                    <Typography variant="body1" color="green">Buy</Typography>
                    </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for displaying single NFT item */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>{selectedItem && selectedItem.title}</DialogTitle>
  <DialogContent>
    <DialogContentText>
      {/* Description of the NFT */}
      {selectedItem && selectedItem.description}
    </DialogContentText>

    {/* Display the image of the NFT */}
    {selectedItem && renderMedia(selectedItem.image, selectedItem.title)}

    {/* Additional information about the NFT */}
    <Box mt={2}>
      <Typography variant="h6">Details:</Typography>
      {/* Include any additional details about the NFT, such as owner, price, etc. */}
      <Typography variant="body1">Owner: {selectedItem && selectedItem.owner}</Typography>
      <Typography variant="body1">Price: {selectedItem && selectedItem.price}</Typography>
      {/* Add more details as needed */}
    </Box>

    {/* Action buttons */}
    <Box mt={2}>
      {/* You can include action buttons here, such as Buy Now, View on Marketplace, etc. */}
      <Button variant="contained" color="primary" onClick={handleItemClick}>
        Buy Now
      </Button>
      <Button variant="outlined" color="primary" onClick={handleItemClick}>
        View on Marketplace
      </Button>
    </Box>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog}>Close</Button>
  </DialogActions>
</Dialog>


    </Container>
  );
};

export default Marketplace;
