import React, { useEffect, useContext, useState } from 'react';
import { UserContext,  } from '../../context/usercontext';
import { Box, Typography,  Drawer, MobileStepper, Dialog} from '@mui/material';
import kcoin from '../../images/kcoin.png';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Akup from '../mods/cssimages/akup';


const imageUrl = "https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp";

const backgroundImages = [
  'https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp',
  'https://storage.googleapis.com/app_darkendimg/assets/koffiemall.webp',
  'https://storage.googleapis.com/app_darkendimg/assets/getbean.jpg',
];

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: 5,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
   
    
  },
  logo: {
    width: 100,
    borderRadius: 0,
   
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 60, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 2, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    
   
  },
  ribbonText: {
    color: 'black', // Adjust the text color of the ribbon
    fontWeight: 600, // Adjust the font weight of the ribbon text
    fontSize: 16, // Adjust the font size of the ribbon text
  },
  
  contentBox: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  signInButtonContainer: {
    width: '100%', // Ensures that the container takes full width
    display: 'flex', // Using flex to center the child items
    justifyContent: 'center', // Centers items horizontally
    alignItems: 'center', // Centers items vertically
    padding: theme.spacing(4), // Adds padding around the container
    backgroundColor: 'transparent',
    
  
  },
  stepperContainer: {
    width: '100%', // Ensures the container takes full width
    display: 'flex',
    justifyContent: 'center', // Centers the stepper horizontally
    padding: theme.spacing(1),
    backgroundColor: 'transparent', // Optional: makes the background of the stepper transparent
  },
}));



// This component will be used to render the Google Sign-In button
// It will use the Google Identity Services library to render the button

//This will use makeStyle to style the button



// This component will be used to render the Google Sign-In button


const GoogleSignIn = () => {
  const { signInWithGoogle } = useContext(UserContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const renderEspresso = () => {
    return (
        <Box  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          
          <div>
        <div id="letter">E</div>
<div id="description">is for Espresso</div>

<div id="animation">
    <div class="machine">
        <div class="lid"></div>
        <div class="groundsHolder"></div>
        <div class="steamer"></div>
        <div class="status">
            <div class="on"></div>
            <div class="off"></div>
        </div>
        <div class="verticalStructure"></div>
        <div class="head"></div>
        <div class="base"></div>
        <div class="spout"></div>
        <div class="back"></div>
    </div>
    
    <div class="cup"></div>
    
    <div class="kettle">
        <div class="spout"></div>
        <div class="lid"></div>
        <div class="handle"></div>
    </div>
    
    <div class="kettlecontents">
        <div></div>
    </div>
    
    <div class="grounds">
        <div class="contents">
            <div></div>
        </div>
        <div class="label">
            <span>C</span>
        </div>
    </div>
    
    <div class="filter">
        <div class="espresso"></div>
        <div class="spigot"></div>
        <div class="handle"></div>
    </div>
</div>


    </div>
     
      </Box>
    );
  }
  const steps = [
    <div style={{ 
   
    
    }}>
    <Box  sx={{
           flexGrow: 1,
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           paddingRight: 2,
           paddingLeft: 2,
           paddingTop: 0,
           backgroundColor: 'transparent',
           boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
           backgroundImage: `url(${backgroundImages[0]})`,
          backgroundSize: 'cover',
           backgroundPosition: 'center',
           backgroundRepeat: 'no-repeat',
           
                 width: '100%',
          
          
        }}
      >
      <img src="https://storage.googleapis.com/app_darkendimg/assets/kland.png" alt="Koffieland Logo" width={100} />
      <Typography variant="h5" gutterBottom sx={{
    color: 'white', 
    fontWeight: 700, 
    textShadow: '2px 2px 8px rgba(0,0,0,0.6)'  // Adjust shadow size and blur for better visibility
}}>
    Welcome to Koffieland™
</Typography>
<Typography variant="body1" sx={{
    color: 'white', 
    textShadow: '1px 1px 4px rgba(0,0,0,0.5)'  // Slightly lighter shadow for smaller text
}}>
    Bringing the world together over koffie.
</Typography>
<Typography variant="h3" sx={{
    color: 'white', 
    fontWeight: 700, 
    textShadow: '2px 2px 8px rgba(0,0,0,0.6)',  // Consistent with the header for uniformity
    mb: 20
}}>
    Get Caffeinated
</Typography>

    </Box>
  </div>,
    <div>
      <Box
    
        sx={{
          flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
    paddingTop: 0,
    backgroundColor: 'transparent',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundImage: `url(${backgroundImages[1]})`,
   backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    
          width: '100%',
          
        }}
      
    >
      
    </Box>
    </div>,


    <div><Box sx={{ flexGrow: 1, }}>
    <Box fullScreen  sx={{ mb: 0,
  
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 2,
    paddingLeft: 2,
    paddingTop: 0,
    backgroundColor: 'transparent',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundImage: `url(${backgroundImages[2]})`,
   backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    
          width: '100%',
    
 
  }}>
   
    
    <Box className={classes.boxContainer}>
      <img src={kcoin} alt='log--koin' className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
     

     
    </Box >

    <Typography variant="body2" component="h1" gutterBottom sx={{mt: 0}}>
        Welcome to Koffieland™
      </Typography>
    <Typography variant="subtitle1" sx={{ mb: 1, textAlign: 'center' }}>
        Bringing the world together over koffie
      </Typography>
      <Typography variant="h4" fontWeight={400} gutterBottom sx={{ mt: 0, textAlign: 'center' }}>
        Build a better Koffieland™
      </Typography>
     
    </Box>
    
  </Box></div>
  ];
  
  useEffect(() => {
    // This function will now call the signInWithGoogle from your UserContext
   // In your GoogleSignIn component

const handleCredentialResponse = (response) => {
  console.log('Encoded JWT ID token:', response.credential);
  // Wrap the string token into an object with 'idToken' property
  signInWithGoogle({ id_token: response.credential });
};


    // Load the Google Identity Services library
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.google.accounts.id.initialize({
        client_id: "213836331022-r0v1mth6ha7603bggo3b4lff361tpr3t.apps.googleusercontent.com", // Replace with your actual client ID
        callback: handleCredentialResponse, // This callback is used for the credential response
        auto_select: true,
        theme: "outline",
        locale: "en-US",
        fetch_basic_profile: true,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("signin-button"), // Ensure this ID matches the element below
        { theme: "outline", 
        size: "large",
        text: "continue_with",
        shape: "pill",
        width: "300px",
        locale: "en-US",
        prompt_parent_id: "signin-button",
        borderRadius: "20px",

        }
      );

     
    };
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [signInWithGoogle]); // Add signInWithGoogle to the dependency array

  return (

    <Box
    fullScreen
    anchor="bottom"
    open={true} // Control this with state to show/hide
    
  
    
sx={{ backgroundColor: 'transparent',  // Ensure the dialog is on top of everything else
backgroundImage: `url(${imageUrl})`, // Set the background image URL here
backgroundSize: 'cover', // Ensure the image covers the full dialog
backgroundPosition: 'center', // Center the background image
backgroundRepeat: 'no-repeat', // Do not repeat the background image
display: 'flex',
flexDirection: 'column',
alignItems: 'center',
justifyContent: 'center',



}} 
    
  >
    <SwipeableViews
      index={activeStep}
      onChangeIndex={(step) => setActiveStep(step)}
      enableMouseEvents
      style={{ height: '100vh',
     backgroundColor: 'transparent', }}
    >
      {steps.map((content, index) => (
        <Box key={index}
        sx={{backgroundColor: 'transparent !important',  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',}}
        >
          <h1>{content}</h1>
        </Box>
      ))}
    </SwipeableViews>
    <Box sx={{
  backgroundColor: 'transparent !important', // Using !important to ensure priority
}}>
  <div id="signin-button" ></div>
  <Typography variant="body2" color='text.secondary' sx={{
    fontWeight: 400,
    textAlign: 'center',
    backgroundColor: 'transparent !important' // Applying transparency here as well
  }}>
    Sign in with your Google account
  </Typography>
  <Typography variant="body2" color='text.secondary' sx={{
    mt: 2,
    textAlign: 'center',
    color: 'green',
    backgroundColor: 'transparent !important' // Ensuring this text also has transparent background
  }}>
    By signing in, you agree to our Terms and Conditions
  </Typography>
  <MobileStepper
    steps={maxSteps}
    position="static"
    variant="dots"
    activeStep={activeStep}
    sx={{
      width: '100%',
      backgroundColor: 'transparent !important', // Force transparency
      justifyContent: 'center',
      marginBottom: 1,
      
    }}
  />
</Box>

   
   
    
  </Box>
    
  );
};

export default GoogleSignIn;