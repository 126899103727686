import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography, Box, Card, CardContent, Avatar, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/system';

const GET_STORY = gql`
  query {
    userprofs {
      _id
      Nickname
      Koffie_story
      imageurl
    }
  }
`;

const ScrollableContainer = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',
  padding: '16px',
  gap: '16px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
});

const StoryCard = styled(Card)({
  width: '250px',
  
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  color: '#352536'
});

function KoffieStory() {
  const { loading, error, data } = useQuery(GET_STORY);
  const [selectedStory, setSelectedStory] = useState(null);

  if (loading) return <div></div>;
  if (error) return <div>Error fetching data</div>;

  // Filter out users with an empty or null Koffie_story
  const stories = (data?.userprofs || []).filter(user => user.Koffie_story && user.Koffie_story.trim() !== "");

  const handleCardClick = (story) => {
    setSelectedStory(story);
  };

  const handleClose = () => {
    setSelectedStory(null);
  };

  return (
    <div>
      <Typography variant='h6' sx={{ fontWeight: 700 }}>Koffie Stories</Typography>
      <ScrollableContainer>
        {stories.map(story => (
          <StoryCard key={story._id} onClick={() => handleCardClick(story)} sx={{ backgroundColor: '#ddfbe5' }}>
            <CardContent sx={{ backgroundColor: '#ddfbe5' }}>
              <Box display="flex" alignItems="center" mb={2} >
                <Avatar src={story.imageurl} alt={story.Nickname} sx={{ marginRight: '8px' , fontWeight: 700}} />
                <Typography variant="h6" noWrap sx={{  fontWeight: 700}}>{story.Nickname}</Typography>
              </Box>
              <Typography variant="body2" sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {story.Koffie_story}
              </Typography>
            </CardContent>
          </StoryCard>
        ))}
      </ScrollableContainer>

      <Dialog open={!!selectedStory} onClose={handleClose}>
        {selectedStory && (
          <>
            <DialogTitle>{selectedStory.Nickname}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {selectedStory.Koffie_story}
              </Typography>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default KoffieStory;
