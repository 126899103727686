// LogoutButton.js
import React, { useContext } from 'react';
import { client, app } from '../../constants/constants'; // Update the path to the correct location
import { UserContext } from '../../context/usercontext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Chip } from '@mui/material';


const LogoutButton = () => {
  const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

  const logOutUser = async () => {
    try {
      if (app.currentUser) {
        await app.currentUser.logOut();
        setUser(null);
  
        // Clear Apollo cache
        await client.clearStore(); // Or client.resetStore();
  
        // Clear local storage
        localStorage.clear(); // Or remove specific items using localStorage.removeItem('keyName')
         // Navigate to the desired page (e.g., the login page)
      navigate('/');
      }
      return true;
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };
  
  return (
   <Chip label="Logout" onClick={logOutUser} sx={{ backgroundColor: '#1ba13f'}} />
  );
};

export default LogoutButton;
