import React, { useContext, useEffect, useState } from 'react';
import {  useQuery, useMutation } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import KupsterDash from '../../pages/kupsterdash';
import gql from 'graphql-tag';
import { useLazyQuery } from "@apollo/client";
import { useLocation } from 'react-router-dom';
import beans from '../../images/coffeebeans.png';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {  styled } from '@mui/material/styles';
import { 
 
  Avatar,
  Grid,
  Card,
  Typography,
  Box,
 CardActionArea,
  CardActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Button,
  } from '@mui/material';

import { makeStyles } from '@mui/styles';
import Flavloader from '../mods/flavloader';
import Update from '../profile/update';
import FarmerDash from '../profile/dashboards/farmerdash';
import { CollaboratorsCount } from '../profile/settings/CollaborateComponents';
import tokenize from '../../images/tokenize.png';
import KoffieCalc from '../finance/koffiecalc';
import Fade from '@mui/material/Fade';
import SwipeDownOutlinedIcon from '@mui/icons-material/SwipeDownOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale , } from 'chart.js/auto'; 
import ShopOn from '../profile/onboarding/shopon';
import CoffeeLoader from '../mods/cssimages/coffeeloader';
import BeanReport from '../finance/prods/beanreport';
import { ArrowForwardIos } from '@mui/icons-material';
import Display from '../profile/settings/displaymode';
import KupOn from '../profile/onboarding/kupon';
import FarmOn from '../profile/onboarding/farmon';
import BaristaOn from '../profile/onboarding/baristaon';
import RoastOn from '../profile/onboarding/roaston';
import CoffeeshopOn from '../profile/onboarding/CoffeeshopOn';
import KTabs from '../nav/tabular';





const userTypes = [
  { type: 'kupster', label: "Kupster's Contracts" },
  { type: 'prodman', label: "Prodman's Contracts" },
  { type: 'barista', label: "Barista's Contracts" },
  { type: 'coffeeShop', label: "Coffee Shop's Contracts" },
  { type: 'roaster', label: "Roaster's Contracts" },
];



function generate(element) {
  return [0,  ].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}



const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    
    borderRadius: '20px',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    fontSize:100,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  body: {
    fontSize: 16,
    color: '#727573',
    padding: 15
  }
}));





function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const theme = useTheme();

    return (
      <div
  role="tabpanel"
  hidden={value !== index}
  id={`full-width-tabpanel-${index}`}
  aria-labelledby={`full-width-tab-${index}`}
  sx={{
    width: '100%',
    bgcolor: 'transparent',
    padding: '5px',
    boxShadow: 'none',
    textTransform: 'lowercase',
    textColor: theme.palette.type === 'dark' ? 'primary' : 'secondary'
  }}
  {...other}
>
  {value === index && (
    <Box sx={{ p: 3, padding: '10px' }}>
      <Typography>{children}</Typography>
    </Box>
  )}
</div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
      
    };
  }



const GET_USER = gql`   
    query Userprof ($_id: ObjectId!)  {
        userprof (query: {_id: $_id}) {
            flavorprofile
            Nickname
            tasteProfile
            description
            name
            purchaseLink
            Koffie_story
            usertype
            _id
            country
            beanCount 
            imageurl
            profileBuilt
            isOnboard
        }
    }
`;




const GET_KOFFY_DETAILS = gql`
    query GetKoffyDetails($countryName: String!) {
        koffy(query: {countryName: $countryName}) {
            countryName
            _id
            Pounds
          
           
        }
    }
`;

const UPDATE_USER_PROF = gql`
  mutation UpdateUserprof($query: UserprofQueryInput!, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      tasteProfile
      description
      name
      country
      usertype
      beanCount
      imageurl
      profileBuilt
      isOnboard
      
      
    }
  }
`;

const DETETE_USER_PROF = gql`
  mutation DeleteUserprof($query: UserprofQueryInput!) {
    deleteOneUserprof(query: $query) {
      _id
    }
  }
`;

const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

const GET_COLLABORATING = gql`
 query GetCollaborating($userId: String!) {
  collaborates(query: { collaboratorID: $userId }) {
    _id
    collaboratingID
  }
}

`;
const GET_COLLABORATORS = gql`
query GetCollaborators($userId: String!) {
  collaborates(query: { collaboratingID: $userId }) {
    _id
    collaboratorID
  }
}
`;

const BeanValueDisplay = ({ beanCount }) => {
  // Assuming beanCount is the number of beans and each bean costs .01 cents
  const costPerBean = 0.01; // Cost per bean in dollars
  const totalValue = beanCount * costPerBean;
  const galleryValue = 30; // Assuming the gallery value is 0 for now
  const totalearnings = beanCount * costPerBean + galleryValue;

  return (
   <Card sx={{ display: 'flex', flexDirection: 'column', padding: 2, boxShadow: 3, borderRadius: 5 }}>
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Bean Value</span>
        <span>{totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
      </li>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Gallery Value</span>
        <span>{galleryValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
      </li>
      <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
        <span>Earnings</span>
        <span><b>{totalearnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b></span>
      </li>
    </ul>
  </Card>
  
  );
};



ChartJS.register(CategoryScale);


function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}



function Logic() {
  
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
    const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [chartReady, setChartReady] = useState(false); // Set to true when the chart is ready to be displayed
  const [expanded, setExpanded] = React.useState();

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeIndex = (index) => {
    
    setValue(index);
  };
    const { user } = useContext(UserContext);
    const location = useLocation();
    const passedUser = location.state?.user; // Access user data passed from Login component
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const classes = useStyles();
    const [updateUserprof] = useMutation(UPDATE_USER_PROF);
    const { data, error, refetch } = useQuery(GET_USER, {
        variables: { _id: user?.id },
    });
    const [deleteUserprof] = useMutation(DETETE_USER_PROF);

    const isOnboardValue = data?.userprof?.isOnboard;
console.log('Type of isOnboard:', typeof isOnboardValue);
console.log('Value of isOnboard:', isOnboardValue);

 //Use profileBuilt from GET_USER query to determine if the user has been welcomed
  const hasBeenWelcomed = isOnboardValue === true || isOnboardValue === 'true';

  //GET COLLABS
  const { loading: loadingCollaborating, error: errorCollaborating, data: collaboratingData } = useQuery(GET_COLLABORATING, {
    variables: { userId: user?.id }, // Assuming user.id is a string
  });
  const { loading: loadingCollaborators, error: errorCollaborators, data: collaboratorsData } = useQuery(GET_COLLABORATORS, {
    variables: { userId: user?.id }, // Assuming user.id is a string
  });
    const [getKoffyDetails, { loading, data: koffyData }] = useLazyQuery(GET_KOFFY_DETAILS);

    console.log('koffie', koffyData);
    
 const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });
 // likes count
  const likesCount = likesData?.likes.length || 0;

  // comments count
  const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });

  if (errorComments) {
    console.error("Error fetching comments:", errorComments.message);
  }
  const commentsCount = commentsData?.comments.length || 0;
//Chart Data

//handle clear profile ask and delete profile ask functions
  const handleDeleteProfile = () => {

      try {
        // Ask the user to confirm the deletion
        const confirmed = window.confirm('Are you sure you want to delete your profile?');
        if (!confirmed) {
          return;
        }
      } catch (error) {
        console.error('Could not confirm deletion:', error);
        return;
      }


    const query = { _id: user.id };
    deleteUserprof({ variables: { query } })
      .then(response => {
        console.log('Profile deleted:', response.data);
      })
      .catch(error => {
        console.error('Could not delete profile:', error);
      });

  };

  // To clear the tasteProfile and description
  const handleClearProfile = () => {
    const query = { _id: user.id };
    const set = { tasteProfile: "", description: "", name: "", country: "" };
  
    updateUserprof({ variables: { query, set } })
      .then(response => {
        console.log('Profile cleared:', response.data);
      })
      .catch(error => {
        console.error('Could not clear profile:', error);
      });
  };
    
    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        if (passedUser) {
          // Do something with the passedUser
        }
    }, [passedUser]);

    useEffect(() => {
        if (user?.id) {
          refetch();
        }
    }, [user?.id, refetch]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    if(loading) return <p><CoffeeLoader/></p>
    if(error) return <p>Error: {error.message}</p>

    let userTypeComponent;

if (data && data.userprof) {
  const userID = data.userprof._id;
  switch (data.userprof.usertype) {
    case 'Farmer':
      userTypeComponent = hasBeenWelcomed ? <KTabs userID={userID} /> : <FarmOn userID={userID} />;
      break;
    case 'kupster':
      userTypeComponent = hasBeenWelcomed ? <KTabs userID={userID} /> : <KupOn userID={userID} />;
      break;
    case 'barista':
      userTypeComponent = hasBeenWelcomed ? <KTabs userID={userID} /> : <BaristaOn userID={userID} />;
      break;
    case 'roaster':
      userTypeComponent = hasBeenWelcomed ? <KTabs userID={userID} /> : <RoastOn userID={userID} />;
      break;
    case 'coffeeShop':
      userTypeComponent = hasBeenWelcomed ? <KTabs userID={userID} /> : <CoffeeshopOn userID={userID} />;
      break;
    default:
      userTypeComponent = <KTabs userID={userID} />;
  }
} else {
  userTypeComponent = <KTabs userID={data?.userprof?._id} />;
}


    const rewardCategories = [
      { title: 'Coffee Points', icon: '☕', description: 'Earn points for every purchase',   },
      { title: 'Special Offers', icon: '🎁', description: 'Exclusive discounts and promotions' },
      { title: 'Premium Membership', icon: '🌟', description: 'Unlock premium benefits' },
      { title: 'Referral Program', icon: '👫', description: 'Refer friends and earn rewards' },
      { title: 'Birthday Treats', icon: '🎂', description: 'Celebrate with special treats' },
    ];
   
    // Filler user details
    const userDetails = {
      name: 'John Doe',
      points: 500,
    };

    const tokens = 1000000; // Total number of tokens in circulation
    
    

    //prepare single user data for the chart from existing data in a function

    const mychart = {
      Nickname: data?.userprof?.Nickname,
      beanCount: data?.userprof?.beanCount,
      imageurl: data?.userprof?.imageurl,
    };

    console.log('mychart', mychart);

    
     
    

    const chartData = {
      datasets: [
        {
          label: 'Top User Bean Count',
          data: [
            {
              x: mychart.Nickname, // Use nickname on X-axis
              y: mychart.beanCount,
              img: mychart.imageurl,
              r: 20, // Adjust the base radius 
              progressPercent: (mychart.beanCount / tokens) * 100, 
            },
          ],
        },
      ],
    };
    
   <Typography variant="body1" color='text.secondary' sx={{ fontWeight: 600 }}>
   {formatBeanCount(user.beanCount)} In Circulation
   </Typography>
   
   const BarChartWithProgress = ({ chartData }) => {
     const data = {
       labels: chartData.datasets[0].data.map((dataPoint) => dataPoint.x), // User nicknames
       datasets: [
         {
           type: 'scatter',
           label: 'Bean Tracker', 
           data: chartData.datasets[0].data.map((dataPoint) => dataPoint.y), // Bean Counts
           backgroundColor: '#32a852', // Green color with transparency
          
          
           
           render: (args) => {
            
             const { chart } = args;
             const { ctx, chartArea } = chart;
             const { data } = args.dataset;
             data.forEach((dataPoint, index) => {
               const { x, y, progressPercent } = dataPoint;
               const { left, right, top, bottom } = chartArea;
               const xCoord = left + (right - left) * chart.data.datasets[0].data[index].x / chart.data.labels.length;
               const yCoord = top + (bottom - top) * chart.scales.y.getValueForPixel(y);
               ctx.save();
               ctx.fillStyle = 'black';
               ctx.textAlign = 'center';
               ctx.textBaseline = 'middle';
               ctx.fillText(`${x}: ${formatBeanCount(y)}`, xCoord, yCoord - 20);
               ctx.fillText(`${progressPercent.toFixed(2)}%`, xCoord, yCoord + 20);
               ctx.restore();
             });
           }
         },
       ],
     };
   
     const options = {
       scales: {
         x: {
           type: 'category', // Use 'category' scale for the x-axis
           ticks: {
             callback: (value) => value, // Display the unique identifier as the x-axis label
           },
         },
         y: {
           beginAtZero: true,
           ticks: {
             callback: (value) => formatBeanCount(value),
           },
         },
       },
       plugins: {
         datalabels: {
           display: true,
           align: 'center',
           anchor: 'center',
           color: 'black',
           
           font: {
             weight: 'bold',
           },
           formatter: (value, context) => {
             return `${context.dataset.data[context.dataIndex].x}: ${formatBeanCount(context.dataset.data[context.dataIndex].y)}`;
           },
         },
       },
       elements: {
         point: {
           radius: 3, // Adjust the radius to make the points bigger
           hoverRadius: 12, // Adjust the hover radius if needed
           hitRadius: 15, // Adjust the hit radius if needed
         },
       },
     };
     
   
     return <Scatter data={data} options={options} />;
   };
   
   console.log(chartData);
   
   BarChartWithProgress.propTypes = {
     chartData: PropTypes.shape({
       datasets: PropTypes.array.isRequired,
     }).isRequired,
   };
    return (   
        <div>   
              
         
              
            <Box sx={{  width: '100%', paddingBottom: 2  }}>
  
                {userTypeComponent}



    </Box>

        </div>
    )
}

export default Logic;
