import React, { useState, useEffect, useContext } from 'react';
import { MobileStepper, Paper, Typography, Button, Box, Chip, Pagination,  Drawer, Dialog } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { UPDATE_USER_PROF, GET_USER } from '../../../context/gqllogics';
import { UserContext } from '../../../context/usercontext';
import { useMutation , } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const steps = [
    {
      label: "Farmers Rock",
      title: "Convert to digibean and get 50% increase",
      content: " We believe in a world where everyone gets a fair deal. We’re not just about good trade, we’re about good trade. We’re about creating a future where everyone benefits, from the farmer to the consumer.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/farmon1.png",
    },
   
    {
      label: "Connect",
      title: "Engage with your coffee drinkers for a seed to cup experience",
      content: " Share your story, your struggles, and your successes. Let them see the real you, and build a loyal following that will support you through thick and thin.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/farmon2.webp",
    },
    {
      label: "Good Trade",
      title: 'Say goodbye to fair-trade',
      content: "We believe in a world where everyone gets a fair deal. We’re not just about good trade, we’re about good trade. We’re about creating a future where everyone benefits, from the farmer to the consumer.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/farmon3.webp",
    },
    {
      label: "Market Place",
      title: "Acess to World Markets",
      content: "Sell your beans to the world",
      imgPath: "https://storage.googleapis.com/app_darkendimg/assets/kupon2.webp",
    },
    
    {
      label: "Disrupt",
      content: "Embrace a future where the coffee industry is just the beginning. Together, we can address wider issues and find solutions that benefit everyone, everywhere. It starts with a bean, but it grows into a movement.",
      imgPath: "https://storage.googleapis.com/app_darkendimg/hilltop.png",
    }
  ];
  

function FarmOn() {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [updateUserprof] = useMutation(UPDATE_USER_PROF, {
    refetchQueries: [{ query: GET_USER, variables: { id: user.id } }],
  });
  

  //navigate to logic page after onboarding

  const goToLogic = () => {
    navigate('/logic');
  }

  const handleComplete = async () => {
    setOpen(false);
  
    try {
      await updateUserprof({
        variables: {
          query: { _id: user.id }, // The query to find the user by ID
          set: { isOnboard: true }, // The fields you want to update
        },
      });
  
      console.log("User onboard status updated successfully.");
      // Direct redirection after the mutation
      goToLogic();
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box  sx={{ flexGrow: 1, height: '100hv' }}>
         <Dialog
      fullScreen
      anchor="botton"
      open={open}
      onClose={handleClose}
      sx={{ 
        height: '100vh', 
        zIndex: 1501,  // Set higher than the bottom navigation
        '& .MuiPaper-root': { 
          width: '100%', // Ensures the drawer covers the full height
          
        },
      }}
    >
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        containerStyle={{ height: 'calc(100% - 0px)' }} // Adjust height to account for pagination and skip chip
      >
        {steps.map((step, index) => (
          <div key={step.label} style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Paper square elevation={0} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingRight:2, paddingLeft: 2, paddingTop: 5, mt: 25  }}>
             
              {step.imgPath && <img src={step.imgPath} alt={step.label} style={{ width: '100%', maxHeight: '60vh', objectFit: 'contain' }} />}
              <Typography variant="h4" sx={{ mb: 1, mt: 4, fontWeight: 700 }}>{step.label}</Typography>
              <Typography variant="h6" sx={{ mb: 2, mt: 2}}>{step.title}</Typography>
              
            </Paper>
          </div>
        ))}
      </SwipeableViews>
      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, py: 2, backgroundColor: 'background.paper' }}>
        <Pagination count={maxSteps} page={activeStep + 1} onChange={(_, value) => handleStepChange(value - 1)} sx={{ justifyContent: 'center', display: 'flex' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <Chip label={`Skip ${activeStep + 1} of ${maxSteps}`} onClick={handleComplete} />
        </Box>
      </Box>
    </Dialog>
    </Box>
  );
}

export default FarmOn;
